import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "submitButton"];

  connect() {
    this.toggleSubmit();
  }

  toggleSubmit() {
    const checkedCheckboxes = this.checkboxTargets.filter(checkbox => checkbox.checked);
    this.submitButtonTarget.disabled = checkedCheckboxes.length === 0;
  }

  checkboxChanged(event) {
    this.toggleSubmit();
  }

  submit(event) {
    const checkboxIds = this.checkboxTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.defaultValue);
    const seatGroupId = event.target.getAttribute('data-seat-group-id');

    $.ajax({
      url: `seat_groups/${seatGroupId}/update_item_delivered`,
      type: 'POST',
      dataType: 'script',
      data: {
        ids: checkboxIds
      },
      success: (data) => {
        const flashBlock = document.getElementById('seat-group-flash-block');
        const message = flashBlock.getAttribute('data-value');
        this.showMessage(message, "success");
        this.closeModal();
      },
      error: (xhr, status, error) => {
        this.showMessage(JSON.parse(xhr.responseText).message || 'エラーが発生しました', "error");
      }
    });
  }

  showMessage(message, type) {
    const flashBlock = document.getElementById('seat-group-flash-block');

    if (type == "success") {
      flashBlock.innerHTML += this.noticeMessage(message);
    } else {
      flashBlock.innerHTML += this.alertMessage(message);
    }

    // 3秒後にメッセージを非表示にする
    setTimeout(() => {
      $(".alert").alert('close')
    },3000);
  }

  noticeMessage(message) {
    return  `
    <div class="alert alert-dismissible mx-2 mx-sm-4 mx-lg-5 mt-3 fade show alert-success">
      <button aria-label="Close" class="close" data-dismiss="alert">
        <span aria-hidden="true">×</span>
      </button>
      <div class="h5 m-0"><div class="text-center word-break-word">${message}</div></div>
    </div>
    `
  }

  alertMessage(message) {
    return  `
    <div class="alert alert-dismissible mx-2 mx-sm-4 mx-lg-5 mt-3 fade show alert-danger">
      <button aria-label="Close" class="close" data-dismiss="alert">
        <span aria-hidden="true">×</span>
      </button>
      <div class="h5 m-0 text-center"><div>${message}</div></div>
    </div>
    `
  }

  closeModal(){
    const modalId = this.getOpenModalId();
    const modal = document.getElementById(modalId);
    $(modal).modal('hide');
    $('.modal-backdrop').remove();
  }

  getOpenModalId() {
    const modals = document.querySelectorAll('.modal');
    for (const modal of modals) {
      if (modal.style.display === "block") {
        return modal.id;
      }
    }
    return null;
  }
}
