import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "visible") {
        setTimeout(() => {
          $.ajax({
            url: "/paypay/complete_payment.json",
            dataType: 'json',
            type: 'GET',
            success: (data) => {
            },
            error: (XMLHttpRequest, textStatus, errorThrown) =>  {
              $(this.element).modal('show');
            }
          });
        }, 1000);
      }
    });

    setTimeout(() => {
      $.ajax({
        url: "/paypay/complete_payment.json",
        dataType: 'json',
        type: 'GET',
        success: (data) => {
        },
        error: (XMLHttpRequest, textStatus, errorThrown) =>  {
          $(this.element).modal('show');
        }
      });
    }, 1000);

  }
}
