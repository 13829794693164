import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    const param = new URLSearchParams(window.location.search);

    if (param.get("status") != null) {
      document.querySelector(`a[href^="#${param.get("status")}"]`).click();
    }
  }

  click() {
    const url = new URL(event.currentTarget.href);

    const params = url.searchParams;
    params.set("status", url.hash.replace("#", ""));
    history.pushState({}, "aaa", url);
  }
}
