import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    order: Object
  }

  connect() {
    // GTMを介してKARTEに情報を送付するために情報を整形し、buyイベントを飛ばす
    window.dataLayer = window.dataLayer || [];

    dataLayer.push({
      transaction_id: this.orderValue.transaction_id,
      revenue : this.orderValue.value,
      bought_items: this.orderValue.items,
      event: "buy"
    });
  }
}
