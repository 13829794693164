import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "favorite", "unfavorite" ]

  connect() {
    this.element.addEventListener("ajax:success", (event) => {
      const response = event.detail[0];
      // Unable to use targets b/c slick clones elements.
      $("."+ response["slug"]+"-favorite").find(".ajax-favorite-button").each(function (i,elem){
        if (response["favorite"]) {
          elem.classList.add('d-none')
        } else {
          elem.classList.remove('d-none')
        }
      })

      $("."+ response["slug"]+"-favorite").find(".ajax-unfavorite-button").each(function (i,elem) {
        if (response["favorite"]) {
          elem.classList.remove('d-none')
        } else {
          elem.classList.add('d-none')
        }
      });
    });

    this.element.addEventListener("ajax:error", function(event) {
      const status = event.detail[1]

      if (status == "Unauthorized") {
        location.href='/log_in';
      }
    })
  }
}
