import { Controller } from "stimulus"

export default class extends Controller {
  static values = { interval: Number, keyboard: Boolean }

  connect() {
    $(this.element).carousel({
      interval: this.intervalValue,
      keyboard: this.keyboardValue,
      ride: 'carousel'
    });
  }
}
