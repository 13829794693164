import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.ajax_autocomplete_wrapper(this.urlValue, this.element);
  }

  ajax_autocomplete_wrapper(url, element) {
    $(element).autocomplete({
      autoFocus: false,
      source: this.ajax_autocomplete(url),
      minLength: 0,
    });
  }

  ajax_autocomplete(url) {
    const ret = function(request, response) {
        if (request.term && request.term.match(/\S/g)) {
            $.ajax({
                url: url + request.term,
                dataType: 'json',
                type: 'GET',
                cache: true,
                success: function(data) {
                    response(data);
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    response(['']);
                }
            });
        }
    }
    return ret;
  }
}
