import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const c_path = location.pathname;
    setTimeout(function () {
      if ( location.pathname == c_path ) {
        location.reload();
      }
    }, 5 * 60 * 1000);
    this.getStatusName()
    this.getTypeName()
    this.getInstantOrderName()
    this.getNamePrefectures()
  }

  preventClose() {
    event.stopPropagation();
  }

  getStatusName() {
    if ($('.select-status').is(':checked')) {
      $('.dropdown-status').text($('.select-status:checked').next("span").text())
    }
  }

  getTypeName() {
    if ($('.select-type').is(':checked')) {
      $('.dropdown-type').text($('.select-type:checked').next("span").text())
    }
  }

  getNamePrefectures() {
    let prefectures = ''
    $('.select-prefectures:checked').each( function (i, prefecture) {
      if ( $(prefecture).is(":checked") ) {
        prefectures += ($(prefecture).next("span").text() + ', ')
      }
      $('.dropdown-prefectures').text(prefectures.slice(0,-2))
    })
    if (prefectures.length > 20) {
      prefectures = prefectures.substring(0, 20) + '...'
      $('.dropdown-prefectures').text(prefectures.slice(0,-2))
    }
  }

  getInstantOrderName() {
    if ($('.select-instant-order').is(':checked')) {
      $('.dropdown-instant-order').text($('.select-instant-order:checked').next("span").text())
    }
  }
}
