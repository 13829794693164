import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "seatCountInput",
    "seatCountError",
    "positionInput",
    "positionError",
    "numPeopleInput",
    "numPeopleError",
    "seatGroupStatusIntervalInput",
    "seatGroupStatusIntervalError"
  ];

  connect() {
    const currentPath = window.location.pathname;
    if(currentPath == '/seat_groups/edit_seat_group') {
      this.seatCountInputTarget.addEventListener('input', this.seatCountValidate.bind(this));
      this.positionInputTarget.addEventListener('input', this.positionValidate.bind(this));
    } else if(currentPath == '/seat_groups/edit_seat_group_index') {
      this.seatGroupStatusIntervalInputTarget.addEventListener('input', this.seatGroupStatusIntervalValidate.bind(this));
    } else {
      this.numPeopleInputTarget.addEventListener('input', this.numPeopleValidate.bind(this));
    }
  }

  seatCountValidate() {
    const inputValue = this.seatCountInputTarget.value.trim();
    const isNumeric = /^\d*$/.test(inputValue);

    if (!isNumeric) {
      this.seatCountErrorTarget.textContent = "座席数には数字を入力してください。";
    } else {
      this.seatCountErrorTarget.textContent = "";
    }
  }

  positionValidate() {
    const inputValue = this.positionInputTarget.value.trim();
    const isNumeric = /^\d*$/.test(inputValue);

    if (!isNumeric) {
      this.positionErrorTarget.textContent = "表示順には数字を入力してください。";
    } else {
      this.positionErrorTarget.textContent = "";
    }
  }

  numPeopleValidate() {
    const inputValue = this.numPeopleInputTarget.value.trim();
    const isNumeric = /^\d*$/.test(inputValue);

    if (!isNumeric) {
      this.numPeopleErrorTarget.textContent = "人数には数字を入力してください。";
    } else {
      this.numPeopleErrorTarget.textContent = "";
    }
  }

  seatGroupStatusIntervalValidate() {
    const inputValue = this.seatGroupStatusIntervalInputTarget.value.trim();
    const isNumeric = /^\d*$/.test(inputValue);

    if (!isNumeric) {
      this.seatGroupStatusIntervalErrorTarget.textContent = "案内可能になるまでの時間には数字を入力してください。";
    } else {
      this.seatGroupStatusIntervalErrorTarget.textContent = "";
    }
  }
}
