import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["menu", "overlay"]

  menu(event) {
    event.stopPropagation();

    if (this.overlayTarget.classList.contains('active')) {
        this.overlayTarget.classList.remove('active');
    } else {
        this.overlayTarget.classList.add('active');
    }
  }

  close() {
    if (this.overlayTarget.classList.contains('active')) {
      this.overlayTarget.classList.remove('active');
    }
  }
}
