import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["cards", "newDeliveryLockerCard"]

  removeDeliveryLocker(event) {
    const deliveryLockerId = event.currentTarget.getAttribute("data-delivery-locker-id-param");
    if(deliveryLockerId) {
      this.cardsTarget.insertAdjacentHTML("afterbegin", `
        <input type="hidden" value="${deliveryLockerId}" name="delivery_lockers[][id]">
        <input type="hidden" value="1" name="delivery_lockers[][_destroy]">
      `);
    }
    event.currentTarget.closest(".card").remove();
  }

  addDeliveryLocker(event) {
    let newCard = this.newDeliveryLockerCardTarget.cloneNode(true);
    newCard.classList.remove("d-none");
    this.cardsTarget.appendChild(newCard);
  }

  toggleDeliveryLockerStatus(event) {
    let button_tag = event.currentTarget;
    let hidden_tag = event.currentTarget.previousElementSibling;

    if(hidden_tag.value == "normal") {
      hidden_tag.value = "stopped";
      button_tag.innerText = "一時停止解除";
      button_tag.classList.remove("btn-dark");
      button_tag.classList.add("btn-white");
    } else {
      hidden_tag.value = "normal";
      button_tag.innerText = "一時停止";
      button_tag.classList.remove("btn-white");
      button_tag.classList.add("btn-dark");
    }
  }
}
