import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    {
      // お支払いボタンの領域を取得
      const float_element = document.querySelector('.float-position');
      // フッター要素を取得
      const footer = document.querySelector('footer');

      if(float_element){
        window.addEventListener('scroll', function() {
          updateFixedPosition();
        });

        // お支払いボタンの領域の固定位置を更新する関数
        function updateFixedPosition() {
          const footerTop = footer.getBoundingClientRect().top;
          const windowHeight = window.innerHeight;
          const elementHeight = float_element.offsetHeight;

          // お支払いボタンの領域がフッターと重なるかどうかを判定
          if (footerTop > windowHeight - elementHeight) {
            // フッターに被らない場合、画面の最下部に固定
            float_element.classList.add('fixed-floating-position');
            float_element.style.bottom = 0;
          } else {
            // フッターに被る場合、フッターの上に固定
            float_element.classList.remove('fixed-floating-position');
          }
        }

        // 初期表示時に固定位置を更新する
        updateFixedPosition();
      }
    }
  }
}
