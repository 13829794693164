import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "label", "preview" ]

  preview(event) {
    const files = event.target.files
    if (!files || !files[0]) return

    const uploaded = files[0]
    const fileType = ['image/jpeg', 'image/png', 'image/webp']

    if ( !fileType.includes(uploaded.type) ) {
      alert('登録可能なファイル形式と異なります。別ファイル形式で再登録ください。');
      return
    }

    this.labelTarget.textContent = uploaded.name

    const reader = new FileReader()
    reader.onload = e => this.previewTarget.setAttribute('src', e.target.result)
    reader.readAsDataURL(uploaded)
  }
}
