import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["scroll"];

  prev() {
    const child_li = this.scrollTarget.getElementsByClassName("order-list");
    const li_w = child_li[0].offsetWidth;
    const sc_pos = this.scrollTarget.scrollLeft;
    if (sc_pos > 0) {
      this.scrollTarget.scrollTo({ left: sc_pos - li_w, behavior: "smooth" });
    }
  }

  next() {
    const child_li = this.scrollTarget.getElementsByClassName("order-list");
    const sc_w = this.scrollTarget.scrollWidth;
    const li_w = child_li[0].offsetWidth;
    const sc_pos = this.scrollTarget.scrollLeft;
    if (sc_pos < sc_w - li_w * 4) {
      this.scrollTarget.scrollTo({ left: sc_pos + li_w, behavior: "smooth" });
    }
  }
}
