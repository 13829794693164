import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["discountKind" ]

  connect() {
    this.showDiscountValue(this.discountKindTarget.value);
    this.checkFacilityChoiced();
  }

  toggleDiscountValue(event) {
    this.showDiscountValue(event.target.value)
  }

  showDiscountValue(val) {
    switch (val) {
      case "kind_percent" :
        $("#coupon_discount_value").show();
        $("#display_discount_kind").text(this.discountKindTarget.options[this.discountKindTarget.selectedIndex].text)
        break;
      case "kind_price" :
        $("#coupon_discount_value").show();
        $("#display_discount_kind").text(this.discountKindTarget.options[this.discountKindTarget.selectedIndex].text)
        break;
      case "kind_delivery_fee" :
        $("#coupon_discount_value").hide();
        $("#display_discount_kind").text("")
        break;
    }
  }

  checkFacilityChoiced() {
    const facilityId = $('#client_facility_id').val();
    const facilitiesData = $('#client_facilities_data').val();
    const facilities = facilitiesData ? JSON.parse(facilitiesData) : [];
    const facility = facilities.find(f => f.id == facilityId);
    this.activeAndInactiveSotetsuPoint(facility);
  }

  handleClientStoreFc(event) {
    const facilityId = event.target.value;
    const facilitiesData = $('#client_facilities_data').val();
    const facilities = facilitiesData ? JSON.parse(facilitiesData) : [];
    const facility = facilities.find(f => f.id == facilityId);
    this.activeAndInactiveSotetsuPoint(facility);
  }

  activeAndInactiveSotetsuPoint(facility) {
    if (facility && facility.sotetsu_point) {
      $('.client_sotetsu_fc').removeClass('sotetsu_inactive')
      $('.client_sotetsu_fc').addClass('sotetsu_active')
    } else {
      $('.client_sotetsu_fc').removeClass('sotetsu_active')
      $('.client_sotetsu_fc').addClass('sotetsu_inactive')
    }
  }
}
