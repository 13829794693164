import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  submit() {
    let passprnt_uri = "starpassprnt://v1/print/nopreview?";

    passprnt_uri = passprnt_uri + "back=" + encodeURIComponent(window.location.href);

    passprnt_uri = passprnt_uri + "&url=" + encodeURIComponent("https://www.star-m.jp/products/s_print/sdk/passprnt/sample/resource/receipt_sample.html");

    window.location.href= passprnt_uri;
  }
}
