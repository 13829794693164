import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    if (document.referrer && document.referrer.indexOf(location.hostname) == -1) {
      // 外部サイトから遷移時に、モーダルを表示する
      $('#orderSelectModal').modal('show');
    } else {
      if (!document.referrer) {
        const perfEntries = window.performance.getEntriesByType("navigation");
        let type = null;

        perfEntries.forEach(function(pe){
          type = pe.type;
        });

        if(type !== 'back_forward'){
          // ブラウザバックでない場合
          $('#orderSelectModal').modal('show');
        }
      }
    }
  }

  connect() {
    // Escキーでモーダルが閉じてしまう現象を防ぐ
    document.getElementById('orderSelectModal').addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        event.stopImmediatePropagation();
      }
    }, true);
  }

  openDeliveryAddressModal() {
    const elements = document.querySelectorAll('.close');

    // カート種別を選択するモーダルから遷移した場合、モーダルを消す要素は隠す
    elements.forEach(function(element) {
      element.style.display = 'none';
    });

    // モーダル外をクリックしてもモーダルが消えないようにする
    $('#deliveryAddressModal').modal({backdrop: 'static'});

    $('#deliveryAddressModal').addClass('modal-open');
    $('#orderSelectModal').modal('hide');
  }
}
