import { Controller } from "stimulus"

export default class extends Controller {
  connect(){
    document.getElementById('submitButton').addEventListener('click', function(event) {
       event.preventDefault();
       $('#orderBillingModal').modal('hide');
       window.location.href = this.href;
    });
  }
  openModal(){
    $('#orderBillingModal').addClass('modal-open');
  }
}
