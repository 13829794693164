import { Controller } from "stimulus";

export default class extends Controller {
  nextTab() {
    const nextElm = $(".shop-page-tab-contens.active").parent().next("li").find("a");

    nextElm.trigger("click");

    if (nextElm[0] != null) {
      nextElm[0].scrollIntoView({block: 'nearest', behavior: "smooth"});
    }
  }

  prevTab() {
    const prevElm = $(".shop-page-tab-contens.active").parent().prev("li").find("a");

    if (prevElm[0] != null) {
      prevElm.trigger("click");
      prevElm[0].scrollIntoView({block: 'nearest', behavior: "smooth"});
    }
  }
}
