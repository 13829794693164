import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.getStatusName()
    this.getEnableName()
  }

  getCategories(url) {
    let categoryName = $(this.element).find(":selected").text()
    window.location.href = `${this.urlValue}?client_name=${categoryName}`
  }

  preventClose() {
    event.stopPropagation();
  }

  getStatusName() {
    if ($('.select-status').is(':checked')) {
      $('.dropdown-status').text($('.select-status:checked').next("span").text())
    }
  }

  getEnableName() {
    if ($('.select-enable').is(':checked')) {
      $('.dropdown-enable').text($('.select-enable:checked').next("span").text())
    }
  }
}
