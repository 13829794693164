import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["willDeliverAt", "willDeliverAtRange"];

  connect() {
    if (this.hasWillDeliverAtRangeTarget) {
      this.updateWillDeliverRange(null, true);
    }

    if (this.hasWillDeliverAtTarget) {
      $(this.willDeliverAtTarget).on("change.datetimepicker", (event) =>
      this.updateWillDeliverAtDate(false)
      );
    }

    this.element.addEventListener("ajax:success", (event) => {
      if (this.updateWillDeliver) {
        document.getElementById('flash-block').innerHTML += this.noticeMessage("お届け時間を設定しました。");
        this.updateWillDeliver = false;
      }
    });

    this.element.addEventListener("ajax:error", (event) => {
      const messages = event.detail[0];

      document.getElementById('flash-block').innerHTML += this.alertMessage(messages);

      setTimeout(() => {
        $(".alert").alert('close')
      },5000);
    });
  }

  updateWillDeliverRange(event, first) {
    let datetime, text;
    const time = $("#guest_cart_will_deliver_at_time").val();

    if (!time) {
      // offer_timeが空の時のjavascriptエラーを回避
      return;
    }

    if (document.getElementsByClassName("datetimepicker").length) {
      datetime = $(".datetimepicker")
        .datetimepicker("date")
        .hour(time.split(":")[0])
        .minutes(time.split(":")[1]);
      const timeFrame = $("#guest_cart_time_frame").data("time-frame");
      text = $("#will_deliver_at_range").data("end-time")
        ? datetime.format("MM月DD日(dd) HH:mm") +
          " 〜 " +
          datetime.add(timeFrame, "minutes").format("HH:mm") + "目安にお届け"
        : datetime.format("MM月DD日(dd) HH:mm") + "目安にお受け取り";
    } else {
      const date = document.getElementById("guest_cart_will_deliver_at").value;
      datetime = moment(`${date} ${time}`);
      text = datetime.format("MM月DD日(dd) HH:mm") + "目安にお受け取り";
    }

    $("#will_deliver_at_range").text(text);
    $("#will_deliver_at_note_time").text(datetime.format("HH:mm"));
    this.updateWillDeliverAtDate(first);
  }

  updateWillDeliverAtDate(first) {
    // Prevent form submission on first page load.
    if ( first || this.willDeliverAtTarget.getAttribute( "data-datetimepicker-init-value" ) !== "true" ) {
      return;
    }
    this.updateWillDeliver = true;
    Rails.fire(this.element, 'submit');
  }

  noticeMessage(message) {
    return  `
    <div class="alert alert-dismissible mx-2 mx-sm-4 mx-lg-5 mt-3 fade show alert-success">
      <button aria-label="Close" class="close" data-dismiss="alert">
        <span aria-hidden="true">×</span>
      </button>
      <div class="h5 m-0"><div class="text-center word-break-word">${message}</div></div>
    </div>
    `
  }
}
