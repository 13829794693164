import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ];

  check(e) {
    const txt = this.inputTarget.value.replace(/\s+/g, '');
    if ( e.keyCode == 13 && txt == '' ) {
      e.preventDefault();
    }
  }
}
