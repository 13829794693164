import { Controller } from "stimulus"

export default class extends Controller {

  checkedOptions() {
    const checked = this.element.closest('.form-group').querySelectorAll('.options_check:checked').length;
    const checkedRequired = 1
    const isOptionRequired = this.element.closest('.form-group').querySelector('.option_select_required').value == 'true';

    if (checked >= checkedRequired || !isOptionRequired) {
      const checkboxs = this.element.closest('.form-group').querySelectorAll('.options_check')
      checkboxs.forEach(function (checkbox) {
        checkbox.removeAttribute('required')
      })
    } else {
      const checkboxs = this.element.closest('.form-group').querySelectorAll('.options_check')
      checkboxs.forEach(function (checkbox) {
        checkbox.setAttribute('required','')
      })
    }
  }
}
