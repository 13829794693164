import { Controller } from "stimulus";
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ["closeButton"];

  connect() {
    this.modal = new Modal(this.element);
  }

  openModal(cart_item) {
    this.cart_item = cart_item;
    this.modal.show();
  }

  cancel() {
    this.closeButtonTarget.click();
    this.onEnd(false);
  }

  delete() {
    this.closeButtonTarget.click();
    this.onEnd(true, this.cart_item);
  }
  getController(event) {
    event.detail.setController((this));
    this.onEnd = event.detail.onEnd;
    return this;
  }
}
