import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.element.addEventListener("ajax:success", (event) => {
      const messages = event.detail[0];
      const flashBlock = document.getElementById('flash-block')

      messages.forEach(message => {
        const type = Object.keys(message)[0];

        switch(type) {
          case "notice":
            flashBlock.innerHTML += this.noticeMessage(message[type]);
            const quantity = message['quantity'];

            if (quantity !== undefined) {
              document.getElementById('cart-quantity').innerHTML = quantity

            }

            setTimeout(() => {
              $(".alert").alert('close')
            },5000);

            break;
          case "alert":
            flashBlock.innerHTML += this.alertMessage(message[type]);

            setTimeout(() => {
              $(".alert").alert('close')
            },5000);

            break;
        }
      });
    });
  }

  noticeMessage(message) {
    return  `
    <div class="alert alert-dismissible mx-2 mx-sm-4 mx-lg-5 mt-3 fade show alert-success">
      <button aria-label="Close" class="close" data-dismiss="alert">
        <span aria-hidden="true">×</span>
      </button>
      <div class="h5 m-0"><div class="text-center word-break-word">${message}</div></div>
    </div>
    `
  }

  alertMessage(message) {
    return  `
    <div class="alert alert-dismissible mx-2 mx-sm-4 mx-lg-5 mt-3 fade show alert-danger">
      <button aria-label="Close" class="close" data-dismiss="alert">
        <span aria-hidden="true">×</span>
      </button>
      <div class="h5 m-0 text-center"><div>${message}</div></div>
    </div>
    `
  }
}
