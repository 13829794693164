import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["orderForm", "paymentForm", "paymentRadioButton", "cardInfo"];

  connect() {
    const getCardControllerEvent = new CustomEvent('getCardController', { detail: {
      setController: (controller)=>{
        this.cardModal=controller;
      },
      onEnd: (ret)=> {
        this.onCardEnd(ret);
      }
    }});
    window.dispatchEvent(getCardControllerEvent);

    const paymentButton = this.paymentRadioButtonTargets.find(target => target.checked)
    if (paymentButton) {
      this.checkBox = paymentButton.defaultValue;

      if (this.checkBox == "Guest::Cart::CardPayment" && this.cardInfoTarget.classList.contains('d-none')) {
        $('#card').modal('show');
      }
    }
  }

  clickCheckbox(event) {
    this.checkBox = event.target.defaultValue;
    Rails.fire(this.paymentFormTarget, 'submit');
  }

  onCardEnd(cardRegistered) {
    if (cardRegistered) {
      this.cardInfoTarget.classList.add('d-display');
      this.cardInfoTarget.classList.remove('d-none');

      document.getElementById('flash-block').innerHTML += this.noticeMessage('カードの登録に成功しました。');

      this.checkBox = "Guest::Cart::CardPayment";

      setTimeout(() => {
        $(".alert").alert('close')
      },5000);
    } else {
      // カードが登録されていない時はラジオボタンを外す
      if (this.cardInfoTarget.classList.contains('d-none')) {
        this.paymentRadioButtonTargets.find(target => target.defaultValue === this.checkBox).checked = true;
        this.paymentRadioButtonTargets[0].checked = false;
      }
    }



    Rails.fire(this.paymentFormTarget, 'submit');
  }

  onDeliveryAddressEnd() {

  }

  submit(e) {
    if (this.paymentRadioButtonTargets.length !== 0 && !this.paymentRadioButtonTargets.find(target => target.checked)) {
      document.getElementById('flash-block').innerHTML += this.alertMessage('支払い方法を設定してください。');

      setTimeout(() => {
        $(".alert").alert('close')
      },5000);

      e.preventDefault();
      return;
    } else {
      Rails.fire(this.paymentFormTarget, 'submit');
    }

    Rails.fire(this.willDeliverFormTarget, 'submit');

    if (!this.orderFormTarget.reportValidity) {
      e.preventDefault();
      return;
    }

    $.ajax({
      url: "cart/check_sms.json",
      dataType: 'json',
      type: 'GET',
      cache: true,
      success: (data) => {
        this.orderFormTarget.submit();
      },
      error: (XMLHttpRequest, textStatus, errorThrown) =>  {
        this.smsModal.openModal();
      }
  });
  }

  noticeMessage(message) {
    return  `
    <div class="alert alert-dismissible mx-2 mx-sm-4 mx-lg-5 mt-3 fade show alert-success">
      <button aria-label="Close" class="close" data-dismiss="alert">
        <span aria-hidden="true">×</span>
      </button>
      <div class="h5 m-0"><div class="text-center word-break-word">${message}</div></div>
    </div>
    `
  }

  alertMessage(message) {
    return  `
    <div class="alert alert-dismissible mx-2 mx-sm-4 mx-lg-5 mt-3 fade show alert-danger">
      <button aria-label="Close" class="close" data-dismiss="alert">
        <span aria-hidden="true">×</span>
      </button>
      <div class="h5 m-0 text-center"><div>${message}</div></div>
    </div>
    `
  }
}
