import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form"];
  updateMemo(event) {
    this.formTarget.submit()
  }

  showSearchForm() {
    $('.show-hide-form').toggleClass('d-none')
    console.log($('.show-hide-form').is(":visible"))
    if ($('.show-hide-form').is(":visible")) {
      $('.arrow-search').removeClass('fa-chevron-left')
      $('.arrow-search').addClass('fa-chevron-down')
    } else {
      $('.arrow-search').removeClass('fa-chevron-down')
      $('.arrow-search').addClass('fa-chevron-left')
    }
  }
}
