import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    // ヘッダーのカート切り替え時
    $('#changeCartModal').on('show.bs.modal', function (event) {
      const button = $(event.relatedTarget);
      const cartType = button.data('cart-type');
      const modal = $(this);
      const link = modal.find('.change-cart');

      if(cartType == 'takeout' || cartType == 'mobile') {
        const url = button.data('submit-url');
        const params = new URLSearchParams({type: cartType});

        link.attr("href", url + "?" + params);
      } else {
        const url = new URL(window.location.origin + link.attr("href"))
        link.attr("href", url.pathname);
      }
    });

    // /delivery_areas, /clients/take_out から画面遷移時
    $('#changeFacilityCartModal').on('show.bs.modal', function (event) {
      const button = $(event.relatedTarget);
      const modal = $(this);
      const link = modal.find('.change-cart')
      const submitProduct = button.data('submit-product');

      if(submitProduct) {
        link.attr("data-submit-product", submitProduct);
      } else {
        const url = button.data('submit-url');
        link.attr("href", url);
      }
    });
  }

  submitCartModal(event) {
    const path = $(event.target).attr('href');
    const url = new URL(window.location.origin + path)
    const query = url.search
    const searchParams = new URLSearchParams(query);
    const cartType = searchParams.get('type');

    if(cartType != 'takeout' && cartType != 'mobile' ) {
      // 配達の時のみリンク無効にした上で別モーダルを表示
      event.preventDefault();
      event.stopImmediatePropagation();
      $('#changeCartModal').modal("toggle");
      $('#deliveryAddressModal').modal("toggle");
    }
  }

  submitFacilityCartModal(event) {
    const path = $(event.target).attr('href');
    const submitProduct = $(event.target).data('submit-product');

    const url = new URL(window.location.origin + path)
    const query = url.search
    const searchParams = new URLSearchParams(query);
    const cartType = searchParams.get('type');

    $('#changeFacilityCartModal').modal('hide');
    $('.modal-backdrop').hide();

    if(submitProduct) {
      const form = $(`.${submitProduct} form.new_guest_cart_client_item`)[0]

      $(form).submit();
    }
  }
}
