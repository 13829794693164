import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["form"];

  submitChange() {
    $("#order-stop-modal").modal("hide");

    const nowUrl = new URL(location.href);
    const sendUrl = new URL(this.formTarget.action);

    sendUrl.search = nowUrl.search;
    this.formTarget.action = sendUrl.href;
    this.formTarget.submit();
  }
}
