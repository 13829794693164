import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ["errorExplanation", "cardNumberDummy", "cardNumber", "cardExpire", "cardSecurityCode", "cardToken", "form", "closeButton"];

  connect() {
    document.getElementById('deliveryAddressModal').addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        event.stopImmediatePropagation();
      }
    }, true);

    this.element.addEventListener("ajax:error", (event) => {
      const messages = event.detail[0].map(message => {
        return `<li>${message}</li>`
      }).join("");

      this.errorExplanationTarget.innerHTML = `
      <div class="alert alert-danger" role="alert">
        <ul>
          ${messages}
        </ul>
      </div>
      `;
    })

    this.element.addEventListener("ajax:success", (event) => {
      this.closeButtonTarget.click();
      this.closeModal(true);
    });
  }

  submit(event) {
    event.preventDefault();

    const data = {};

    let card_expire_month = this.cardExpireTargets[0].value
    if (card_expire_month.length == 1) { card_expire_month = '0' + card_expire_month }

    data.token_api_key = veritrans_token_api_key;
    data.card_number = this.cardNumberTarget.value;
    data.card_expire = card_expire_month + '/' + this.cardExpireTargets[1].value;
    data.security_code = this.cardSecurityCodeTarget.value;
    data.lang = "ja";

    const url = "https://api.veritrans.co.jp/4gtoken";
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    xhr.addEventListener('loadend', () => {
      if (xhr.status === 0) {
        alert("トークンサーバーとの接続に失敗しました");
        return;
      }
      const response = JSON.parse(xhr.response);
      if (xhr.status == 200) {
        this.cardExpireTargets[0].value = "";
        this.cardExpireTargets[1].value = "";
        this.cardNumberTarget.value = "";
        this.cardNumberDummyTarget.value = "";
        this.cardSecurityCodeTarget.value = ""
        this.cardTokenTarget.value = response.token;

        this.req_card_number = response.req_card_number
        if (this.element.classList.contains('modal')) {
          Rails.fire(this.formTarget, 'submit');
        } else {
          this.formTarget.submit();
        }
      }
      else {
        alert(response.message);
      }
    });
    xhr.send(JSON.stringify(data));
  }

  // 0-9の数字以外が入っていたとしても、submitで弾かれるので、高速化のため、ここではvalidationしない
  check(event) {
     // 空白を削除した文字列に変換（16桁にカット）
    const str = this.cardNumberDummyTarget.value.replace(/\s/g, '').substring(0, 16);

    this.cardNumberTarget.value = str;

    let result = str.substring(0, 4) + ' ' + str.substring(4, 8) + ' ' + str.substring(8, 12) + ' ' + str.substring(12, 16);

    // 頭尾がスペースなら、削除しておく
    result = result.trim();
    this.cardNumberDummyTarget.value = result;
  }

  close() {
    this.closeButtonTarget.click();
    this.closeModal(false);
  }

  openModal() {
    new Modal(this.element).show();
  }

  closeModal(cardRegistered) {
    this.onEnd(cardRegistered);
  }

  getController(event) {
    event.detail.setController((this));
    this.onEnd = event.detail.onEnd;
    return this;
  }
}
