import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    order: Object
  }

  connect() {
    // Must fire after gtag('config', ...) PageView event.
    gtag('event', 'purchase', this.orderValue)
  }
}
