import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    userId: String,
    firstName: String,
    lastName: String,
    email: String,
    login: String
  }
  static targets = [
    "sidebar",
    "alert"
  ];

  initialize() {
    this.sendGoogleAnalyticsPageView()
    if ( window.newPort && window.newPort['ga_id'] ) {    // ゲストとクライアントからの呼び出しに対し、ゲストの時だけ実行
      this.sendVirtualPageView()
    }
  }

  connect() {
    if (typeof YubinBango != 'undefined') {
      new YubinBango.MicroformatDom()
    }

    /* not sure if necessary */
    /*
    if (typeof FontAwesome != 'undefined') {
      FontAwesome.dom.i2svg();
    }
    */

    if (this.hasAlertTarget) {
      setTimeout(() => {
        $(this.alertTarget).alert('close')
      }, 3000);
    }

    this.adjustWindowHeight()
  }

  disconnect() {
    /* unset Zendesk widget loading checker */
    window.zEACLoaded = false;
  }

  sendGoogleAnalyticsPageView() {
    if (window.newPort && window.newPort['ga_id']) {
      const args = {
        'page_path': location.pathname + location.search
      }

      if (window.newPort['ga_user_id']) {
        args['user_id'] = window.newPort['ga_user_id']
      }

      gtag('config', window.newPort['ga_id'], args);
    }
  }

  sendVirtualPageView() {
    dataLayer = dataLayer || [];

    if (this.userIdValue != '') {
      dataLayer.push({
        user_id : this.userIdValue,
        first_name : this.firstNameValue,
        last_name : this.lastNameValue,
        email : this.emailValue
       })
    }

    dataLayer.push({
     'event':'virtualPageView'
    })

    if (this.loginValue != '') {
      dataLayer.push({
        'event':'user-logined',
        login: this.loginValue
       })
    }
  }

  adjustWindowHeight(event) {
    clearTimeout(this.debounce)
    this.debounce = setTimeout(() => {
      $('.main').css('padding-top', $(".navbar").height())
    }, 100)
  }

  toggleSideMenu(){
    this.sidebarTarget.hidden = !this.sidebarTarget.hidden;
  }
}
