import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = [ "form", "alert", "postalCodeField", "prefectureField", "cityField",
    "streetField", "buildingField", "latitudeField", "longitudeField", "link" ]
  static deliveryAddressFields = [ 'id', 'postal_code', 'prefecture_id', 'city',
      'street', 'building' ]

  delivery_open() {
    this.linkTarget.classList.add('d-none');
    this.linkTarget.classList.remove('d-display');

    new Modal(this.element).show();
  }

  initialize() {
    if (typeof google !== 'undefined') {
      this.geocoder = new google.maps.Geocoder()
    }
  }

  connect() {
    const storedValue = sessionStorage.getItem('selectOrderType');

    // カート種別が選択されていない状態ではEscキーを押してもモーダルが消えないようにする
    if(storedValue == null) {
      document.getElementById('deliveryAddressModal').addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
          event.stopImmediatePropagation();
        }
      }, true);
    }

    $(this.element).on('hidden.bs.modal', () =>  {
      this.linkTarget.classList.add('d-display');
      this.linkTarget.classList.remove('d-none');
    })
  }

  fixInvalidAddress(event) {
    if (event.target.dataset.validity !== 'false') return

    event.preventDefault()
    event.stopPropagation()
    this.alertTarget.style.display = 'block'
    this.constructor.deliveryAddressFields.forEach((field) => {
      document.getElementById('guest_delivery_address_' + field).value =
        event.target.dataset[this.snakeToCamel(field)] || ''
    })
  }

  submitForm(event) {
    event.preventDefault()

    const postal_code = this.postalCodeFieldTarget.value
    const prefectureField = this.prefectureFieldTarget
    const city = this.cityFieldTarget.value
    const street = this.streetFieldTarget.value
    const address = `${postal_code} ${prefectureField.options[prefectureField.selectedIndex].text}${city}${street}`

    this.geocode(address)
  }

  geocode(address) {
    /* Stub for test. */
    if (location.host == 'guest.test-sky-farm.me:41111') {
      return this.setCordinate(35.4687189, 139.6161165)
    }

    this.geocoder.geocode({'address': address, 'region': 'JP' }, (results, status) => {
      if (status == 'OK') {
        const lat = results[0].geometry.location.lat()
        const lng = results[0].geometry.location.lng()
        this.setCordinate(lat, lng)
      } else {
        console.log('Geocode was not successful for the following reason: ' + status);
      }
    })
  }

  setCordinate(latitude, longitude) {
    this.latitudeFieldTarget.value = latitude
    this.longitudeFieldTarget.value = longitude

    this.formTarget.submit()
  }

  snakeToCamel(s) {
    return s ? s.toLowerCase().replace(/(_\w)/g, (m) => m[1].toUpperCase()) : ''
  }

  getController(event) {
    event.detail.setController((this));
    this.onEnd = event.detail.onEnd;
    return this;
  }
}
